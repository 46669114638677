import React from "react";
import App from "next/app";
import { Amplify, Auth } from "aws-amplify";
import dynamic from "next/dynamic";
import { ToastContainer } from "react-toastify";
import Router, { withRouter } from "next/router";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import "./../src/data/designClasses.css";
import Cookies from "js-cookie";
import * as workerTimers from "worker-timers";
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import { defaultSeo } from "../src/config/next-seo.config";
import "./../public/css/App.css";
import "./../public/css/Styles.css";
import '../public/css/designClasses.css';
import '../public/css/RevampStaticPages.css';
// import "font-awesome/css/font-awesome.min.css";
import Script from "next/script";
import { setJwtToken } from "../src/crud/axios";
import awsconfig from "./../src/config/aws.exports";
import RedirectChecker from "./../src/common/RedirectChecker";
import AuthChecker from "./../src/common/AuthChecker";
import AuthContext from "./../src/contexts/AuthContext";
import logger from "./../src/common/Logger";
import Outage from "./outage";
import { isMaintenanceTime } from "../src/config/config";

// Dynamically import non-critical components
const CookiePopup = dynamic(() => import("../src/revampComponents/CookiePopup"));
const SubscribeNewsletterModal = dynamic(() => import("../src/pages/Newsletter/SubscribeNewsletterModal"));

// Initialize Amplify
Amplify.configure({
	...awsconfig,
	ssr: true
});

class MyApp extends App {
	constructor(props) {
		Cookies.remove('authToken');
		super(props);
		this.state = {
			user: this.props.user || { attributes: {} },
			signedIn: this.props.signedIn !== undefined ? this.props.signedIn : null,
			overlay: true,
			refreshToken: null,
			hasCookiesAccepted: Cookies.get("Cookie") !== undefined
		};
	}

	componentWillMount() {
		if (isMaintenanceTime) Router.push("/outage");
	}

	async componentDidMount() {
		const queryParams = this.props.router.query;
		let refreshToken = null;
		Router.events.on("routeChangeComplete", this.authAndRedirectCheck);
		const { user, signedIn } = await AuthChecker(null, true);
		if (!signedIn && Cookies.get("authToken") !== undefined) {
			Cookies.remove("authToken");
			this.props.router.replace("/", undefined, { shallow: false });
		} else if (!signedIn) {
			RedirectChecker(this.state.signedIn, {
				pathname: this.props.router.pathname,
				asPath: this.props.router.asPath
			});
		} else {
			if (signedIn) setJwtToken(user.signInUserSession.idToken.jwtToken);
			refreshToken = this.refreshToken();
		}

		// Defer UTM parameter storage to improve initial load
		setTimeout(() => {
			if (queryParams.utm_campaign)
				localStorage.setItem("utm_campaign", queryParams.utm_campaign);
			if (queryParams.utm_medium) localStorage.setItem("utm_medium", queryParams.utm_medium);
			if (queryParams.utm_source) localStorage.setItem("utm_source", queryParams.utm_source);
		}, 0);

		this.setState({ ...this.state, user, signedIn, refreshToken });
	}

	componentWillUnmount() {
		Router.events.off("routeChangeComplete", this.authAndRedirectCheck);
	}

	refreshToken = async () => {
		return workerTimers.setInterval(
			async () => {
				try {
					const user = await Auth.currentAuthenticatedUser();
					const session = await Auth.currentSession();
					user.refreshSession(session.refreshToken, (err, session) => {
						const { idToken } = session;
						setJwtToken(idToken.jwtToken);
					});
				} catch (err) {
					clearInterval(refreshToken);
					window.location.href = "/logout";
				}
			},
			60 * 60 * 1000
		);
	};

	authAndRedirectCheck = async () => {
		const authData = await AuthChecker(null);
		RedirectChecker(authData.signedIn, {
			pathname: this.props.router.pathname,
			asPath: this.props.router.asPath
		});
	};

	setAuth = (user = {}, signedIn = true) => {
		clearInterval(this.state.refreshToken);
		if (signedIn) {
			this.refreshToken();
		}
		this.setState({ ...this.state, user, signedIn });
	};

	hideOverlayLoading = () => {
		if (
			typeof document !== "undefined" &&
			document.getElementsByClassName("overlay-loader")[0] !== undefined
		) {
			document.getElementsByClassName("overlay-loader")[0].classList.remove("d-block");
			document.getElementsByClassName("overlay-loader")[0].classList.add("d-none");
		}
	};
	acceptCookie = () => {
		this.setState({ ...this.state, hasCookiesAccepted: true });
	};

	render() {
		const { Component, pageProps, err } = this.props;
		const { user, signedIn, hasCookiesAccepted } = this.state;
		pageProps.signedIn = signedIn;

		return (
			<>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={true}
					closeOnClick
					rtl={false}
					draggable
					pauseOnHover
				/>
				<DefaultSeo {...defaultSeo} />
				<Head>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href="/favicon/apple-touch-icon.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="32x32"
						href="/favicon/favicon-32x32.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="16x16"
						href="/favicon/favicon-32x32.png"
					/>
					<link rel="manifest" href="/favicon/site.webmanifest" />
					<link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
					<link rel="preconnect" href="https://www.google-analytics.com" />
					<link rel="preconnect" href="https://ssl.google-analytics.com" />
					<link rel="preconnect" href="https://www.googletagmanager.com" />
					<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" />

				</Head>
				{/* <Script
					src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js"
					integrity="sha384-ygbV9kiqUc6oa4msXn9868pTtWMgiQaeYH7/t7LECLbyPA2x65Kgf80OJFdroafW"
					crossOrigin="anonymous"
				/> */}
				<AuthContext.Provider
					value={{
						user,
						signedIn,
						setAuth: this.setAuth,
						hasCookiesAccepted,
						acceptCookie: this.acceptCookie
					}}
				>
					<>
						<Component {...pageProps} err={err} />
						{this.hideOverlayLoading()}
					</>
					<CookiePopup route={this.props.router.route} />
					<SubscribeNewsletterModal route={this.props.router.route} signedIn={signedIn} />
				</AuthContext.Provider>
			</>
		);
	}
}

export default withRouter(MyApp);