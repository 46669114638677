import { createLogger, format } from "winston";
import { Loggly } from "winston-loggly-bulk";

const logger = createLogger({
	level: "error",
	format: format.combine(format.timestamp(), format.json()),
	transports: [
		new Loggly({
			token: "87c85971-2dd7-4af5-b672-d2aa61c0f5ba",
			subdomain: "konfhub",
			tags: [`[KonfHub-2.0-${process?.env?.ENV_NAME || "local"}]`],
			json: true
		})
	]
});

export default logger;
