import React from "react";
import { withRouter } from "next/router";
import OutagePage from "../src/pages/Outage";


const Outage = () => {
  return (
    <div>
      <OutagePage />
    </div>
  );
};

export default withRouter(Outage);
