const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
const compareDate = new Date("2025-11-04T00:00:00.000+05:30");

export const hostEventUrl = "https://events.konfhub.com";
export const certhubUrl = "https://cert.konfhub.com"
export const quizhubUrl = "https://quiz.konfhub.com";
export const baseUrl = "https://konfhub.com";
export const loginUrl = "https://accounts.konfhub.com/login";
export const gMapApiKey = "AIzaSyBkCKa3Rg5BeGbk2uxvOVFEbLcxNulxLOc";
export const GoogleAnalyticsId = "G-LZKZSZBMDY";
export const ClarityId = "fkqnen70dz";
export const s3Url = `https://konfhub-image-files-prod.s3.ap-south-1.amazonaws.com`;
export const mediaUrl = `https://media.konfhub.com`;
export const xApiKey = '61fdb3d7-063f-43de-9c92-54bcf9b3a7c4';
export const publicStripePublishableKey = 'pk_live_51Nx4nbSBwIcmEW9pi6Ya2dUuG3DCoLNqYmgDB5bDmq2niuKne2pIVOYDk7cPVbb3RlABOFaFd4GkxvIr3ZwmC7QS00LKhXP1G9'
export const facebook_id = '429779859341568';
export const newRazorPayEventIds = ['e03ec4fc-12c3-436e-a85f-388edbde0e28', 'a8ca47aa-d795-437c-8568-d43ad42d86ea'];
export const pgBasic = 10720;
export const pgPlus = 10721;
export const pgUltimate = 10722;

export const razorpayKey =
	currentDate - compareDate < 0
		? 'rzp_live_HhzNsEpElC165q'
		: 'rzp_live_PUd2oi7NPziudN';
export const newRazorPayKey = 'rzp_live_PUd2oi7NPziudN';

const maintenanceStartDate = new Date(
	"2024-10-31T03:20:00.000+05:30"
);
const maintenanceEndDate = new Date(
	"2024-10-31T03:30:00.000+05:30"
);

export const isMaintenanceTime =
	currentDate > maintenanceStartDate && currentDate < maintenanceEndDate;
