import axios from "axios";
import Router from "next/router";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";

export const rawAxios = axios.create();

let jwtToken = "";

export const setJwtToken = token => (jwtToken = token);

axios.interceptors.request.use(
	async config => {
		const token = jwtToken;
		if (token !== undefined && token !== "") {
			if (!config.headers["authorization"] && !config.headers["Authorization"])
				config.headers["Authorization"] = `${token}`;
		}
		config["Accept-Encoding"] = "gzip, compress, deflate, br";
		return config;
		// if(!token){
		// 	try{
		// 		let user = await Auth.currentAuthenticatedUser()
		// 		token = user.signInUserSession.idToken.jwtToken
		// 	}
		// 	catch(err){
		// 		console.log(err)
		// 	}
		// 	config.headers['Authorization'] = `${token}`;
		// 	return config;
		// }else{
		// 	config.headers['Authorization'] = `${token}`;
		// 	return config;
		// }
	},
	error => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => {
		if (response.data.statusCode >= 400 || response.data.errorMessage) {
			const error = {
				message: "Something went wrong!"
			};
			if (response.data.statusCode === 500) error.message = "Something went wrong!";
			else {
				if (response.data.errorMessage) error.message = response.data.errorMessage;
				if (response.data.error) error.message = response.data.error;
				if (response.data.message) error.message = response.data.message;
				if (response.data.message.message) error.message = response.data.message.message;
			}
			throw error;
		} else {
			if (response.data.error) throw new Error(response.data.error);
			// if(response.data) response.data = response.data.message;
			if (response.data) response.data = response.data;
		}
		return response;
	},
	error => {
		if (error.response) {
			// if(error.response.status === 404) Router.push("/error", undefined, { shallow: false });
			if (error.response.status === 401 && error.response.data === "Not Authorised")
				Router.push("/error", undefined, {
					shallow: false
				});
			if (error.response.status === 401)
				Router.push("/logout", undefined, {
					shallow: false
				});
			if (error.response.data?.error) {
				error.message = error.response.data?.error;
			}
		}
		return Promise.reject(error);
	}
);

export default axios;
