import { baseUrl } from "./config";

const awsconfig = {
	aws_project_region: "ap-south-1",
	aws_cognito_identity_pool_id: "ap-south-1:0bf02d86-5b7b-4665-964d-cb52f756903b",
	aws_cognito_region: "ap-south-1",
	aws_user_pools_id: "ap-south-1_acJo3XGbL",
	aws_user_pools_web_client_id: "5jf10rk2c0muftotp1iu2ucup0",
	oauth: {
		domain: "auth.konfhub.com",
		redirectSignIn: baseUrl,
		scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
		redirectSignOut: baseUrl,
		responseType: "code"
	},
	cookieStorage: {
		domain: ".konfhub.com",
		secure: false,
		path: "/",
		expires: 365
	}
};

export default awsconfig;
