import React from "react";

const Outage = () => {
	return (
		<>
			<div className="outage-container d-flex h-100 align-items-center flex-column text-center">
				<img className="image-container" src="/logos/error.svg" alt="KonfHub Outage" />
				<p className="outage-text">
					Sorry, we're down for scheduled maintenance right now.
				</p>
				<p className="outage-text">We'll be back soon!</p>
			</div>

			<style jsx global>{`
				.outage-container {
					padding: 0 3rem 3rem 3rem;
				}
				.image-container {
					width: 475px;
					height: 455px;
					margin-bottom: 30px;
				}
				.outage-text {
					max-width: 680px;
					font-family: "Nunito";
					font-weight: 500;
					font-size: 24px;
					line-height: 32px;
					color: #572148;
					margin-bottom: 5px;
				}
				@media only screen and (max-width: 576px) {
					.image-container {
						width: 355px;
						height: 340px;
					}
					.outage-text {
						width: 100%;
						margin-bottom: 20px;
						font-size: 20px;
						line-height: 27px;
					}
					.outage-container {
						padding: 0 1rem 1rem 1rem;
					}
				}
			`}</style>
		</>
	);
};

export default Outage;