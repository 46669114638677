import { withSSRContext, Auth } from "aws-amplify";
import Cookies from "js-cookie";

const AuthChecker = (ctx, cacheSkip = false) => {
	return new Promise(resolve => {
		let user = { attributes: {} },
			signedIn = false,
			AuthFetcher = Auth,
			bypassCache = false;
		if (ctx && ctx.res) {
			const WithSSR = withSSRContext(ctx);
			AuthFetcher = WithSSR.Auth;
		}
		bypassCache = cacheSkip !== undefined ? cacheSkip : false;
		AuthFetcher.currentAuthenticatedUser({
			bypassCache
		})
			.then(data => {
				user = data;
				signedIn = true;
				if (ctx === null)
					Cookies.set("authToken", "true", {
						expires: 365,
						sameSite: "strict"
					});
				else {
					if (!ctx.res)
						Cookies.set("authToken", "true", {
							expires: 365,
							sameSite: "strict"
						});
					else {
						ctx.req.headers.cookie += `;authToken=true`;
					}
					if (ctx !== null && ctx.res) console.log("success log -- ssr");
					else console.log("success log -- cs");
				}
				resolve({ user, signedIn, bypassCache });
			})
			.catch(err => {
				if (ctx && ctx.res) console.log("error log -- ssr - ", err);
				else console.log("error log -- cs - ", err);
				if (ctx && ctx.res) {
					if (ctx.req && ctx.req.headers && ctx.req.headers.cookie) {
						if (ctx.req.headers.cookie.includes("authToken")) signedIn = null;
					}
				}
				resolve({ user, signedIn, bypassCache });
			});
	});
};

export default AuthChecker;
